<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          My Courses
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Classes :classes="student.session_classes" />
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Classes from "@/components/userProfile/StudentClasses";
import profileApi from "@/apis/profile";

export default {
  metaInfo() {
    return {
      title: "My Courses - Ivy-Way Academy"
    };
  },

  components: {
    Breadcrumb,
    Classes
  },

  mixins: [],

  props: [],
  data() {
    return {
      student: null
    };
  },
  computed: {
    ...mapState("user", ["profile"]),
    userRoleProfile() {
      return this.$store.getters["profile/getUserData"];
    }
  },
  watch: {},

  async mounted() {
    const res = await profileApi.getUserRoleProfile();
    this.student = res.student;
  },

  methods: {}
};
</script>

<style scoped></style>
